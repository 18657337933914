:root {
    --space-2: 0.2rem;
    --space-4: 0.4rem;
    --space-8: 0.8rem;
    --space-10: 1rem;
    --space-12: 1.2rem;
    --space-16: 1.6rem;
    --space-20: 2rem;
    --space-24: 2.4rem;
    --space-28: 2.8rem;
    --space-30: 3rem;
    --space-32: 3.2rem;
    --space-36: 3.6rem;
    --space-40: 4rem;
    --space-45: 4.5rem;
    --space-48: 4.8rem;
    --space-50: 5rem;
    --space-56: 5.6rem;
    --space-60: 6rem;
    --space-64: 6.4rem;
    --space-70: 7rem;
    --space-72: 7.2rem;
    --space-80: 8rem;
}
