:root {
    --shadow-xs: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
    --shadow-s: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    --shadow-m: 0px 3px 6px 0px rgba(0, 0, 0, 0.08);
    --shadow-l: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
    --shadow-xl: 0px 6px 12px 0px rgba(0, 0, 0, 0.08);
    --shadow-2xl: 0px 24px 24px 0px rgba(0, 0, 0, 0.12),
        0px 12px 24px 0px rgba(0, 0, 0, 0.12);
    --shadow-3xl: 0px 36px 36px 0px rgba(0, 0, 0, 0.18),
        0px 18px 36px 0px rgba(0, 0, 0, 0.12);
    --shadow-4xl: 0px 48px 48px 0px rgba(0, 0, 0, 0.26),
        0px 24px 48px 0px rgba(0, 0, 0, 0.14);

    --shadow-xs-hover: 0px 6px 12px 0px rgba(0, 0, 0, 0.12);
    --shadow-s-hover: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
    --shadow-m-hover: 0px 12px 24px 0px rgba(0, 0, 0, 0.12);
    --shadow-l-hover: 0px 16px 32px 0px rgba(0, 0, 0, 0.12);
    --shadow-xl-hover: 0px 20px 40px 0px rgba(0, 0, 0, 0.16);
    --shadow-2xl-hover: 0px 24px 50px 0px rgba(0, 0, 0, 0.18);
}
