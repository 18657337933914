.modal-overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;

    display: flex;
    z-index: 9999;

    @media (max-width: 26.75em) {
        .modal {
            width: 80%; /* Adjust the width for smaller screens */
            padding: var(--space-16); /* Adjust padding for smaller screens */
        }
    }

    @media (max-width: 25em) {
        .modal {
            width: 90%; /* Further adjust the width for screens of 25em or smaller */
            padding: var(--space-10);
        }
    }
}

.modal-overlay--center {
    justify-content: center;
    align-items: center;
}

.modal {
    width: 50rem;

    padding: var(--space-24);
    background-color: var(--black-200);
    border-radius: var(--border-radius-16);
    box-shadow: var(--shadow-3xl);
    transition: all 5s;
    opacity: 0.9s;
}

.modal-overlay--right {
    justify-content: flex-end;
}

.modal-overlay--right .modal {
    border-radius: 1;
}
