.btn {
    font-family: inherit;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-700);
    line-height: var(--line-height-30);
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    cursor: pointer;

    height: 3.6rem;
    padding: 0 var(--space-14);
    transition: all 0.3s;
}

.btn-full-width {
    width: 100%;
}

.btn:hover {
    cursor: pointer;
}

.btn:disabled {
    pointer-events: none;
}

.btn-small {
    height: 3.6rem;
}

.btn-medium {
    height: 3rem;
    padding: 0 var(--space-18);
}

.btn-large {
    height: 5rem;
    padding: 0 var(--space-20);
}

.btn-rounded {
    border-radius: var(--border-radius-8);
}

.btn-circle {
    border-radius: var(--border-radius-20);
}

.btn-large.btn-circle {
    border-radius: var(--border-radius-24);
}

/* Primary Button */

.btn-primary {
    background-color: var(--primary);
    color: var(--white);
    box-shadow: var(--shadow-s);
}

.btn-primary:hover {
    background-color: var(--primary);
    box-shadow: var(--shadow-s-hover);
}

.btn-primary:disabled {
    background-color: var(--primary-300);
}

.btn-primary:focus {
    background-color: var(--primary-700);
    box-shadow: var(--shadow-xs-hover);
}

.btn-primary.btn-outlined {
    background-color: var(--white);
    border: 0.15rem solid var(--primary-500);
    color: var(--primary-500);
}

.btn-primary.btn-outlined:hover {
    background-color: var(--primary-25);
}

.btn-primary.btn-outlined:focus {
    background-color: var(--primary-50);
}

.btn-primary.btn-outlined:disabled {
    border: 0.15rem solid var(--jaguar-300);
    color: var(--jaguar-300);
}

.btn-primary.btn-text {
    background-color: var(--white);
    color: var(--primary-500);
    box-shadow: none;
}

.btn-primary.btn-text:hover {
    background-color: var(--primary-25);
}

.btn-primary.btn-text:focus {
    background-color: var(--primary-50);
}

.btn-primary.btn-text:disabled {
    color: var(--jaguar-300);
}

/* Secondary Button */
.btn-secondary {
    background-color: var(--jaguar-500);
    color: var(--white);
    box-shadow: var(--shadow-s);
}

.btn-secondary.btn-contained {
    background-color: inherit;
    color: var(--white);
    box-shadow: var(--shadow-3xl);
}
.btn-secondary:hover {
    background-color: var(--dodge-blue-400);
    border: 0.1rem solid var(--jaguar-200);
    box-shadow: var(--shadow-2xl-hover);
}

.btn-secondary:disabled {
    background-color: var(--jaguar-300);
}

.btn-secondary:focus {
    background-color: var(--jaguar-700);
    box-shadow: var(--shadow-xs-hover);
}

.btn-secondary.btn-outlined {
    background-color: var(--white);
    border: 0.15rem solid var(--jaguar-200);
    color: var(--black-900);
}

.btn-secondary.btn-outlined:hover {
    background-color: var(--black-50);
}

.btn-secondary.btn-outlined:focus {
    background-color: var(--black-100);
}

.btn-secondary.btn-outlined:disabled {
    border: 0.15rem solid var(--jaguar-300);
    color: var(--black);
}

.btn-secondary.btn-text {
    background-color: var(--white);
    color: var(--jaguar-500);
    box-shadow: none;
}

.btn-secondary.btn-text:hover {
    background-color: var(--black-900);
}

.btn-secondary.btn-text:focus {
    background-color: var(--black-100);
}

.btn-secondary.btn-text:disabled {
    color: var(--jaguar-300);
}

/* Danger Button */
.btn-danger {
    background-color: var(--red-orange-500);
    color: var(--white);
    box-shadow: var(--shadow-s);
}

.btn-danger:hover {
    background-color: var(--red-orange-600);
    box-shadow: var(--shadow-s-hover);
}

.btn-danger:disabled {
    background-color: var(--red-orange-300);
}

.btn-danger:focus {
    background-color: var(--red-orange-700);
    box-shadow: var(--shadow-xs-hover);
}

.btn-danger.btn-outlined {
    background-color: var(--white);
    border: 0.15rem solid var(--red-orange-500);
    color: var(--red-orange-500);
}

.btn-danger.btn-outlined:hover {
    background-color: var(--red-orange-25);
}

.btn-danger.btn-outlined:focus {
    background-color: var(--red-orange-50);
}

.btn-danger.btn-outlined:disabled {
    border: 0.15rem solid var(--red-orange-300);
    color: var(--red-orange-300);
}

.btn-danger.btn-text {
    background-color: var(--white);
    color: var(--red-orange-500);
    box-shadow: none;
}

.btn-danger.btn-text:hover {
    background-color: var(--red-orange-25);
}

.btn-danger.btn-text:focus {
    background-color: var(--red-orange-50);
}

.btn-danger.btn-text:disabled {
    color: var(--red-orange-300);
}

/* Success Button */
.btn-success {
    background-color: var(--dodger-blue-900);
    color: var(--white);
    box-shadow: var(--shadow-s);
}

.btn-success:hover {
    background-color: var(--dodger-blue-600);
    box-shadow: var(--shadow-s-hover);
}

.btn-success:disabled {
    background-color: var(--dodger-blue-300);
}

.btn-success:focus {
    background-color: var(--dodger-blue-700);
    box-shadow: var(--shadow-xs-hover);
}

.btn-success.btn-outlined {
    background-color: var(--white);
    border: 0.15rem solid var(--green-500);
    color: var(--green-500);
}

.btn-success.btn-outlined:hover {
    background-color: var(--green-25);
}

.btn-success.btn-outlined:focus {
    background-color: var(--green-50);
}

.btn-success.btn-outlined:disabled {
    border: 0.15rem solid var(--green-300);
    color: var(--green-300);
}

.btn-success.btn-text {
    background-color: var(--white);
    color: var(--green-500);
    box-shadow: none;
}

.btn-success.btn-text:hover {
    background-color: var(--green-25);
}

.btn-success.btn-text:focus {
    background-color: var(--green-50);
}

.btn-success.btn-text:disabled {
    color: var(--green-300);
}
