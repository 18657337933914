:root {
    --primary-12: #fbf9ff;

    --primary-25: #f7f3fe;

    --primary-50: #f0e7fe;

    --primary-100: #d7c4fc;

    --primary-200: #bb9cfb;

    --primary-300: #9e70fb;

    --primary-400: #844efa;

    --primary-500: #6826f7;

    --primary-600: #5b21f0;

    --primary-700: #4617e8;

    --primary-800: #2c0ee3;

    --primary-900: #00d;

    --sunglow-12: #fffdf8;

    --sunglow-25: #fffbf0;

    --sunglow-50: #fff7e1;

    --sunglow-100: #ffebb4;

    --sunglow-200: #fede84;

    --sunglow-300: #fed254;

    --sunglow-400: #fdc632;

    --sunglow-500: #fcbd1f;

    --sunglow-600: #fcaf1a;

    --sunglow-700: #fb9d19;

    --sunglow-800: #fa8c18;

    --sunglow-900: #f96e16;

    --jaguar-12: #fdfcff;

    --jaguar-25: #fbf9ff;

    --jaguar-50: #f7f3ff;

    --jaguar-100: #ece8fc;

    --jaguar-200: #ddd9ed;

    --jaguar-300: #c8c5d8;

    --jaguar-400: #a29fb2;

    --jaguar-500: #817d8f;

    --jaguar-600: #9d9d9d;

    --jaguar-700: #474555;

    --jaguar-800: #2a2836;

    --jaguar-900: #2a2836;

    --dodger-blue-12: #f8fcff;

    --dodger-blue-25: #f1f9ff;

    --dodger-blue-50: #e3f3ff;

    --dodger-blue-100: #bbe0ff;

    --dodger-blue-200: #99e0ff;

    --dodger-blue-300: #58baff;

    --dodger-blue-400: #5f9ffe;

    --dodger-blue-500: #009aff;

    --dodger-blue-600: #048bff;

    --dodger-blue-700: #1977fb;

    --dodger-blue-800: #2165e8;

    --dodger-blue-900: #0252cd;

    --green-12: #3e7741;

    --green-25: #ebf5ec;

    --green-50: #d8ebd9;

    --green-100: #c4e1c6;

    --green-200: #b1d7b3;

    --green-300: #9dcda0;

    --green-400: #89c28d;

    --green-500: #76b87a;

    --green-600: #62ae67;

    --green-700: #53a058;

    --green-800: #498d4d;

    --green-900: #3e7742;

    --light-green-12: #fbfff9;

    --light-green-25: #f7fff2;

    --light-green-50: #f0ffe5;

    --light-green-100: #d9ffbe;

    --light-green-200: #bcff91;

    --light-green-300: #99ff59;

    --light-green-400: #74ff03;

    --light-green-500: #48fd00;

    --light-green-600: #28ea00;

    --light-green-700: #00d400;

    --light-green-800: #00c000;

    --light-green-900: #009b00;

    --dark-green-500: #56b0b9;

    --black-12: #fdfdfd;

    --black-25: #fafafa;

    --black-50: #f5f5f5;

    --black-100: #e9e9e9;

    --black-200: #f6f6f6;

    --black-300: #c4c4c4;

    --black-400: #9d9d9d;

    --black-500: #7b7b7b;

    --black-600: #555;

    --black-700: #434343;

    --black-800: #262626;

    --black-900: #000;

    --red-orange-12: #fffafb;

    --red-orange-25: #fff5f6;

    --red-orange-50: #ffebee;

    --red-orange-100: #ffcdd2;

    --red-orange-200: #f59b99;

    --red-orange-300: #ec7372;

    --red-orange-400: #f7534d;

    --red-orange-500: #fc4130;

    --red-orange-600: #ee3831;

    --red-orange-700: #db2d2b;

    --red-orange-800: #cf2524;

    --red-orange-900: #bf1817;

    --rose-12: #fff8fb;

    --rose-25: #fff1f6;

    --rose-50: #ffe3ed;

    --rose-100: #ffb9d2;

    --rose-200: #ff89b4;

    --rose-300: #ff5496;

    --rose-400: #ff197d;

    --rose-500: #ff0064;

    --rose-600: #ed0061;

    --rose-700: #d7005d;

    --rose-800: #c2005b;

    --rose-900: #9c0056;

    --purple-12: #fef9ff;

    --purple-25: #fcf2fe;

    --purple-50: #fae5fe;

    --purple-100: #f1bcfc;

    --purple-200: #e88dfb;

    --purple-300: #dd56fb;

    --purple-400: #d400f9;

    --purple-500: #c900f6;

    --purple-600: #b600f1;

    --purple-700: #9b00ed;

    --purple-800: #8300ea;

    --purple-900: #4c00df;

    --blue-ribbon-12: #f9faff;

    --blue-ribbon-25: #f3f4ff;

    --blue-ribbon-50: #e8eaff;

    --blue-ribbon-100: #c6c9fd;

    --blue-ribbon-200: #9da6fb;

    --blue-ribbon-300: #7083fb;

    --blue-ribbon-400: #4965f9;

    --blue-ribbon-500: #0247f5;

    --blue-ribbon-600: #003ee9;

    --blue-ribbon-700: #0032dd;

    --blue-ribbon-800: #0025d2;

    --blue-ribbon-900: #0000c2;

    --black-100: #151e2c;

    --white: #ffffff;
    --transparent: transparent;
    --overlay-40: rgba(0, 0, 0, 0.4);
}
